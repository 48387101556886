<template>
  <div>Hello</div>
</template>

<script>
import Communicator from '../libraries/apiCom.js'
import Actions from '../libraries/apiActions.js';
import apiCom from '../libraries/apiCom.js';

export default {
    name: 'redirect',
    props: ['user'],
    data(){
      return {
        apiCom:new Communicator(),
      }
    },
    beforeMount() {
    // let api = this.apiAction.getUser();
    let api = new apiCom("seeker", "/me");
    // let token = {token:this.$route.query.token};
    let src = this.$route.query.src;
    let redirectRoute = "/myprofile";
    let redirect_from = this.$route.query.redirect_from;
    // this.$cookies.set("hire-seeker", token);

    api.post({}, { Authorization: "Bearer " + this.$route.query.token }).then((data) => {
      if (data) {
        if(!this.$route.query.autologin) {
          new Actions().verifyEmail({token: this.$route.query.token}).then(res => {
            if(res.status) {
              let token = {token:this.$route.query.token,name:data.user.name,visume_path:data.user.personal_details.video_resume_path,resume_path:data.user.personal_details.resume_path,alert:(!data.user.personal_details.resume_path||!data.user.personal_details.video_resume_path)?'yes':'no'};
              this.$cookies.set("hire-seeker", token);
              if(redirect_from) {
                if(src) redirect_from += '?src='+src;
                // window.location.href = process.env.VUE_APP_URL_WS+redirect_from;
                window.location.href = redirect_from;

              } else if(src === 'seeker_job_description'){
                window.location.href = process.env.VUE_APP_URL_WS+'/jobs/'+redirect_from;
              }else{
                this.$router.push(redirectRoute);
                location.reload();
              }
            }
          })
        } else {
          let token = {token:this.$route.query.token,name:data.user.name,visume_path:data.user.personal_details.video_resume_path,resume_path:data.user.personal_details.resume_path,alert:(!data.user.personal_details.resume_path||!data.user.personal_details.video_resume_path)?'yes':'no'};
          this.$cookies.set("hire-seeker", token);
          if(this.$route.query.redirect_from) {
            window.location.href = process.env.VUE_APP_URL_WS+redirect_from;
          } else{
            this.$router.push(redirectRoute);
            location.reload();
          }
        }
      } else { 
        this.$router.replace("/").catch(() => {});
      }
    }).catch((err) => {
      console.log(err);
      if (err) {
        this.$router.replace("/").catch(() => {});
      }
    });
  }
}
</script>
